<template>
  <div>
    <vx-card>
      <!-- TABLE ACTION ROW -->
      <div class="flex justify-between items-center">
        <vs-input
          class="mb-4 w-full md:mb-0 mr-4"
          v-model="searchQuery"
          placeholder="Buscar negociación"
        />
        <div>
          <vs-button
            :to="{ name: 'configuracion-crear-negociacion' }"
            class="mb-4 md:mb-0"
            >Crear</vs-button
          >
        </div>
      </div>
      <div v-if="!loading && !negotiationsLoading">
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="agGridState.gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="agGridState.defaultColDef"
          :rowData="negotiations"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          :overlayLoadingTemplate="overlayLoadingTemplate"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="agGridState.maxPageNumbers"
          v-model="currentPage"
        />
      </div>
      <div v-else class="h-64 my-24 flex justify-center items-center">
        <p class="text-grey">Cargando...</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { useAgGrid } from '@/composable/useAgGrid.js';
import { NEGOTIATIONS } from '@/graphql/queries.js';
import { useQuery, useResult } from '@vue/apollo-composable';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';

import CellRendererAmount from '@/components/cell-renderer/CellRendererAmount.vue';
import CellRendererPercentAmount from '@/components/cell-renderer/CellRendererPercentAmount.vue';
import CellRendererBoolean from './cell-renderer/CellRendererBoolean.vue';
import CellRendererNegotiationEdit from './cell-renderer/CellRendererNegotiationEdit.vue';

export default defineComponent({
  components: {
    CellRendererAmount,
    CellRendererNegotiationEdit,
    CellRendererPercentAmount,
    CellRendererBoolean,
  },
  setup(_, { root }) {
    const state = reactive({
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 100,
          filter: true,
        },
        {
          headerName: 'Editar',
          field: 'editar',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererNegotiationEdit',
        },
        {
          headerName: 'Nombre',
          field: 'name',
          width: 400,
          filter: true,
        },
        {
          headerName: 'Compañía',
          field: 'company.name',
          filter: true,
        },
        {
          headerName: 'Redondear costo',
          field: 'round_cost',
          filter: true,
          cellRendererFramework: 'CellRendererBoolean',
        },
      ],
      loading: false,
      overlayLoadingTemplate:
        '<span>Please wait while your rows are loading</span>',
    });

    const currentPage = ref(1);
    const first = ref(20);
    const searchQuery = ref('');
    const paginationPageSize = ref('');

    const { result: negotiationsResult, loading: negotiationsLoading } =
      useQuery(NEGOTIATIONS, {
        page: currentPage,
        first: first,
        search: searchQuery,
      });

    watch(negotiationsLoading, (value) => {
      state.loading = value;
    });

    const negotiations = useResult(
      negotiationsResult,
      [],
      (data) => data.negotiations.data
    );

    const paginatorInfo = useResult(negotiationsResult, [], (data) => ({
      total: data.negotiations.paginatorInfo.total,
      perPage: data.negotiations.paginatorInfo.perPage,
    }));

    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );

    const { state: agGridState } = useAgGrid({ root });

    return {
      ...toRefs(state),
      agGridState,
      totalPages,
      currentPage,
      negotiations,
      searchQuery,
      negotiationsLoading,
      paginationPageSize,
    };
  },
});
</script>
