<template>
  <div>
    <feather-icon
      v-if="params.value"
      icon="CheckIcon"
      svgClasses="h-5 w-5 mr-4 text-primary"
    />
    <feather-icon
      v-else
      icon="XCircleIcon"
      svgClasses="h-5 w-5 mr-4 text-nepal"
    />
  </div>
</template>

<script>
export default {
  name: 'CellRendererBoolean',
};
</script>
